import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../components/Layout";
import Col from "react-bootstrap/Col";
import ReactMarkdown from "react-markdown";
import Title from "../components/Title";

// markup
const SurroundingsPage = () => {
  const data = useStaticQuery(query);

  return (
    <Layout pageTitle="Rowy i okolice">
      <Col md="auto">
        <Title>Rowy i okolice</Title>
        <ReactMarkdown>{data.strapiRowyIOkolice.Opis}</ReactMarkdown>
      </Col>
    </Layout>
  );
};

const query = graphql`
  query {
    strapiRowyIOkolice {
      Opis
    }
  }
`;

export default SurroundingsPage;
